import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { Card, Form, Table } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Loading from '../shared/Loading/Loading';
import WrapperCard from '../shared/Cards/WrapperCard';

const GroupManagement = (props) => (
  <WrapperCard
    header={{
      exist: true,
      data: { iconName: 'bx bx-group', title: 'Group Management' },
    }}
  >
    <Card.Body>
      <div className="row">
        <div className="col-12 col-md-6">
          <Form.Group controlId="dateRange2">
            <Form.Label>Created Date Range</Form.Label>
            <DateRangePicker
              initialSettings={{
                timePicker: true,
                locale: {
                  cancelLabel: 'Clear',
                  format: 'DD/MM/YYYY hh:mm A',
                },
                autoUpdateInput: false,
              }}
              onEvent={(event, picker) =>
                props.handlers.handleDatePickerGroup(event, picker)
              }
            >
              <Form.Control
                type="text"
                className="form-control datetime"
                placeholder="Select Date"
                aria-invalid="false"
                autoComplete="off"
              />
            </DateRangePicker>
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <div className="contact-management-buttons">
            <button
              type="button"
              className="btn main-btn icon-btn"
              onClick={() => props.handlers.handleShowAddGroupContactModal()}
            >
              <i className="bx bx-group" />
              Add Contact Group
            </button>
          </div>
        </div>
      </div>
    </Card.Body>
    <Card.Body className="pt-0">
      <div className="row">
        <div className="col-12 col-md-6">
          <Form.Group controlId="groupFilter">
            <Form.Label>Filter By</Form.Label>
            <Select
              isMulti
              name="filterBy"
              placeholder="Select Group"
              options={props.data.groupListFilter}
              className="basic-multi-select"
              classNamePrefix="select"
              menuPlacement="auto"
              styles={{
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#f58025',
                  color: '#fff',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: '#fff',
                }),
              }}
              onChange={(e) => props.handlers.handleGroupFilterGroup(e)}
            />
          </Form.Group>
        </div>
        <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
          <button
            type="submit"
            className="btn main-btn"
            onClick={() => props.handlers.handleShowDataGroups(true)}
            disabled={props.data.showGroupLoading}
          >
            {props.data.showGroupLoading ? <Loading /> : <span>Show Data</span>}
          </button>
        </div>
        <div className="col-12 mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th>#ID</th>
                <th>Group Name</th>
                <th>Total Contacts</th>
                <th>Created Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {props.data.showGroupLoading && (
                <tr>
                  <td colSpan="5" className="loading-white-bg">
                    <Loading />
                  </td>
                </tr>
              )}
              {!props.data.showGroupLoading &&
                props.data.groupNameList.length > 0 &&
                props.data.groupNameList.map((group) => (
                  <tr key={group.id}>
                    <td>{group.id}</td>
                    <td>{group.name}</td>
                    <td>{group.totalContacts.toLocaleString()}</td>
                    <td>
                      {moment(group.created_at).format(
                        'DD MMMM YYYY, h:mm:ss a'
                      )}
                    </td>
                    <td>
                      <i
                        className="bx bx-trash"
                        onClick={() =>
                          props.handlers.handleDeleteGroup(group.id)
                        }
                      />
                    </td>
                  </tr>
                ))}
              {!props.data.showGroupLoading &&
                props.data.groupNameList.length === 0 && (
                  <tr className="text-center">
                    <td colSpan="5">
                      <img
                        alt="no-data-found"
                        src={require('../../assets/shared/search.svg').default}
                      />
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
          {props.data.groupPagination.totalData > 10 && (
            <div className="pagination-custom-style">
              <Pagination
                activePage={props.data.groupPagination.currentPage}
                itemsCountPerPage={props.data.groupPagination.perPage}
                totalItemsCount={props.data.groupPagination.totalData}
                onChange={(e) => props.handlers.handleGroupPageChange(e)}
                hideFirstLastPages
                prevPageText="<PREV"
                nextPageText="NEXT>"
                activeClass="active-page"
                activeLinkClass="active-link"
                itemClass=""
                linkClass="defaul-link"
              />
            </div>
          )}
        </div>
      </div>
    </Card.Body>
  </WrapperCard>
);

export default GroupManagement;
