/* Constants */

const production = {
  urls: {
    /* Need to swap api_base_url before pushing to production */
    api_base_url: 'https://umobile-messaging.lawaapp.com/api/api/v1', // Staging server
    // api_base_url: 'https://enterprise-api.cpa-umobile.com/api/v1', // Production server
    api_messages_documentation: 'https://www.google.com/',
  },

  // REDUX STORE PERSIST CONFIG
  persistConfig: {
    key: 'UMOBILE-PROD',
  },

  sentry: {
    env: 'production',
    dsn: 'https://794b260e3ba3447c91ad1d4a46f6ee87@o252633.ingest.sentry.io/5825719',
  },

  messageStatus: [
    { label: 'Success', value: 'delivered' },
    { label: 'Failed', value: 'failed' },
    { label: 'Pending', value: 'pending' },
  ],

  utc: [
    { label: '-12', value: '-12' },
    { label: '-11', value: '-11' },
    { label: '-10', value: '-10' },
    { label: '-9', value: '-9' },
    { label: '-8', value: '-8' },
    { label: '-7', value: '-7' },
    { label: '-6', value: '-6' },
    { label: '-5', value: '-5' },
    { label: '-4', value: '-4' },
    { label: '-3', value: '-3' },
    { label: '-2', value: '-2' },
    { label: '-1', value: '-1' },
    { label: '0', value: '0' },
    { label: '+1', value: '+1' },
    { label: '+2', value: '+2' },
    { label: '+3', value: '+3' },
    { label: '+4', value: '+4' },
    { label: '+5', value: '+5' },
    { label: '+6', value: '+6' },
    { label: '+7', value: '+7' },
    { label: '+8', value: '+8' },
    { label: '+9', value: '+9' },
    { label: '+10', value: '+10' },
    { label: '+11', value: '+11' },
    { label: '+12', value: '+12' },
  ],

  months: [
    { value: '1', month: 'Jan' },
    { value: '2', month: 'Feb' },
    { value: '3', month: 'Mar' },
    { value: '4', month: 'Apr' },
    { value: '5', month: 'May' },
    { value: '6', month: 'June' },
    { value: '7', month: 'July' },
    { value: '8', month: 'Aug' },
    { value: '9', month: 'Sept' },
    { value: '10', month: 'Oct' },
    { value: '11', month: 'Nov' },
    { value: '12', month: 'Dec' },
  ],

  msClientId: 'a0763e38-8d2e-4b02-b47e-452925927448',
};

const development = {
  urls: {
    api_base_url: 'http://10.90.24.200:8004/api/v1', // Staging server
    api_messages_documentation: 'https://www.google.com/',
  },

  // REDUX STORE PERSIST CONFIG
  persistConfig: {
    key: 'UMOBILE-DEV',
  },

  sentry: {
    env: 'development',
    dsn: 'https://794b260e3ba3447c91ad1d4a46f6ee87@o252633.ingest.sentry.io/5825719',
  },

  messageStatus: [
    { label: 'Success', value: 'delivered' },
    { label: 'Failed', value: 'failed' },
    { label: 'Pending', value: 'pending' },
  ],

  utc: [
    { label: '-12', value: '-12' },
    { label: '-11', value: '-11' },
    { label: '-10', value: '-10' },
    { label: '-9', value: '-9' },
    { label: '-8', value: '-8' },
    { label: '-7', value: '-7' },
    { label: '-6', value: '-6' },
    { label: '-5', value: '-5' },
    { label: '-4', value: '-4' },
    { label: '-3', value: '-3' },
    { label: '-2', value: '-2' },
    { label: '-1', value: '-1' },
    { label: '0', value: '0' },
    { label: '+1', value: '+1' },
    { label: '+2', value: '+2' },
    { label: '+3', value: '+3' },
    { label: '+4', value: '+4' },
    { label: '+5', value: '+5' },
    { label: '+6', value: '+6' },
    { label: '+7', value: '+7' },
    { label: '+8', value: '+8' },
    { label: '+9', value: '+9' },
    { label: '+10', value: '+10' },
    { label: '+11', value: '+11' },
    { label: '+12', value: '+12' },
  ],

  months: [
    { value: '1', month: 'Jan' },
    { value: '2', month: 'Feb' },
    { value: '3', month: 'Mar' },
    { value: '4', month: 'Apr' },
    { value: '5', month: 'May' },
    { value: '6', month: 'June' },
    { value: '7', month: 'July' },
    { value: '8', month: 'Aug' },
    { value: '9', month: 'Sept' },
    { value: '10', month: 'Oct' },
    { value: '11', month: 'Nov' },
    { value: '12', month: 'Dec' },
  ],

  msClientId: 'a0763e38-8d2e-4b02-b47e-452925927448',
};

const config =
  process.env.NODE_ENV === 'development' ? development : production;

export default config;
