import React from 'react';

const Footer = () => {
  return (
    <div className="Footer">
      <span>2021 © U Mobile Sdn Bhd</span>
    </div>
  );
};

export default Footer;
