import {
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  UPDATE_LOADING_STATUS,
  UPDATE_STAY_SIGN_IN,
  UPDATE_USER_PROFILE,
  UPDATE_USER_CREDITS,
  UPDATE_MSAL,
} from '../actionTypes';

const INITIAL_STATE = {
  isLoggedIn: false,
  token: null,
  refreshToken: null,
  msal: {},
  user: {
    companyId: {
      address: '',
      country: '',
      createdAt: '',
      emailDomain: '',
      id: 1,
      name: '',
      phone: '',
      postpaid: 0,
      updatedAt: '',
      uuid: '',
    },
    createdAt: '',
    email: 'user@email.com',
    encoding: '',
    id: 1,
    phone: '',
    position: '',
    skype: '',
    status: '',
    technical: 0,
    updatedAt: '',
    username: 'User name',
    utc: '',
    credits: {
      type: '',
      credit: '',
    },
  },
  staySignIn: 0,
  isLoading: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        user: {
          ...state.user,
          companyId: {
            address: action.payload.companyId.address,
            country: action.payload.companyId.country,
            createdAt: action.payload.companyId.createdAt,
            emailDomain: action.payload.companyId.emailDomain,
            id: action.payload.companyId.id,
            name: action.payload.companyId.name,
            phone: action.payload.companyId.phone,
            postpaid: action.payload.companyId.postpaid,
            updatedAt: action.payload.companyId.updatedAt,
            uuid: action.payload.companyId.uuid,
          },
          createdAt: action.payload.createdAt,
          email: action.payload.email,
          encoding: action.payload.encoding,
          id: action.payload.id,
          phone: action.payload.phone,
          position: action.payload.position,
          skype: action.payload.skype,
          status: action.payload.status,
          technical: action.payload.technical,
          updatedAt: action.payload.updatedAt,
          username: action.payload.username,
          utc: action.payload.utc,
        },
      };
    case UPDATE_STAY_SIGN_IN:
      return {
        ...state,
        staySignIn: action.payload,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          companyId: {
            address: action.payload.companyId.address,
            country: action.payload.companyId.country,
            createdAt: action.payload.companyId.createdAt,
            emailDomain: action.payload.companyId.emailDomain,
            id: action.payload.companyId.id,
            name: action.payload.companyId.name,
            phone: action.payload.companyId.phone,
            postpaid: action.payload.companyId.postpaid,
            updatedAt: action.payload.companyId.updatedAt,
            uuid: action.payload.companyId.uuid,
          },
          createdAt: action.payload.createdAt,
          email: action.payload.email,
          encoding: action.payload.encoding,
          id: action.payload.id,
          phone: action.payload.phone,
          position: action.payload.position,
          skype: action.payload.skype,
          status: action.payload.status,
          technical: action.payload.technical,
          updatedAt: action.payload.updatedAt,
          username: action.payload.username,
          utc: action.payload.utc,
        },
      };
    case UPDATE_USER_CREDITS:
      return {
        ...state,
        user: {
          ...state.user,
          credits: {
            type: action.payload.credit.type,
            credit: action.payload.credit.credit,
          },
        },
      };
    case UPDATE_MSAL:
      return {
        ...state,
        msal: action.payload,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default authReducer;
