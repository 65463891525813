import React, { Component } from 'react';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as GsmCharsetUtils from '@trt2/gsm-charset-utils';
import { Card, Form } from 'react-bootstrap';
import { getRequest, postRequest, putRequest } from '../../helpers/apiHandlers';
import { phoneRegex } from '../../helpers/regex';
import { getUserCredits } from '../../store/actions/authAction';
import WrapperCard from '../../components/shared/Cards/WrapperCard';
import AddPhoneNumber from '../../components/WebMessaging/AddPhoneNumber';
import SendLaterModal from '../../components/WebMessaging/SendLaterModal';
import HOC from '../../components/shared/HOC';
import Loading from '../../components/shared/Loading/Loading';
import SendMessageTable from '../../components/WebMessaging/SendMessageTable';

class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupListFilter: [],
      scheduleList: [],
      tag: '',
      tags: [],
      selectedGroup: [],
      messageContent: 'RM0 ',
      scheduleName: '',
      preferedDateTime: null,
      dateValue: '',
      showSendLaterModal: false,
      scheduleListLoading: false,
      sendNowLoading: false,
      sendLaterLoading: false,
      shortenURLLoading: false,
      currentPage: 1,
      perPage: 10,
      totalData: 0,

      /* Error Handling */
      tagsError: false,
      selectedGroupError: false,
      tagsSelectedGroupError: false,
      messageContentError: false,
      scheduleNameError: false,
      preferedDateTimeError: false,
    };

    this.getGroupListData = this.getGroupListData.bind(this);
    this.getSchedulesData = this.getSchedulesData.bind(this);
    this.handleSendNow = this.handleSendNow.bind(this);
    this.handleSendLater = this.handleSendLater.bind(this);
    this.handleShortenURL = this.handleShortenURL.bind(this);
    this.handleUpdateScheduleStatus =
      this.handleUpdateScheduleStatus.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.resetStates = this.resetStates.bind(this);
    this.handleChangeTagInput = this.handleChangeTagInput.bind(this);
    this.handleChangeTags = this.handleChangeTags.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
    this.handleShowSendLaterModal = this.handleShowSendLaterModal.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.getGroupListData();
  }

  dataModeling(data) {
    if (data.length > 0) {
      let tempData = [];

      data.map((item) =>
        tempData.push({
          ...item,
          label: item.name,
          value: item.id,
        })
      );

      return tempData;
    }
  }

  getGroupListData() {
    this.setState({ scheduleListLoading: true });
    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/groups', data, (res) => {
      if (res.success) {
        const groupListFilter =
          res.data.groups.length > 0 ? this.dataModeling(res.data.groups) : [];
        this.setState(
          {
            groupListFilter: groupListFilter,
          },
          () => this.getSchedulesData()
        );
      } else {
        // console.log(res);
      }
    });
  }

  getSchedulesData(showData) {
    if (!this.state.scheduleListLoading) {
      this.setState({ scheduleListLoading: true });
    }

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        page: showData ? 1 : this.state.currentPage,
        perPage: this.state.perPage,
      },
    };

    getRequest('/message/schedules', data, (res) => {
      if (res.success) {
        this.setState({
          scheduleList: res.data.contains,
          currentPage: res.data.pagination.currentPage,
          perPage: res.data.pagination.perPage,
          totalData: res.data.pagination.totalData,
          scheduleListLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ scheduleListLoading: false });
      }
    });
  }

  checkVal(fieldName, value) {
    if (fieldName === 'tagsSelectedGroup') {
      if (value.length === 0) {
        this.setState({
          [fieldName + 'Error']: true,
        });
      }
    } else if (fieldName === 'preferedDateTime') {
      if (value === null) {
        this.setState({
          [fieldName + 'Error']: true,
        });
      }
    } else if (fieldName === 'messageContent') {
      const testMessageContent = value.match('RM0 ');
      if (value === testMessageContent[0]) {
        this.setState({
          [fieldName + 'Error']: true,
        });
      }
    } else {
      if (value.trim() === '') {
        this.setState({
          [fieldName + 'Error']: true,
        });
      }
    }
  }

  handleSendNow() {
    const { tags, messageContent, selectedGroup } = this.state;
    const testMessageContent = messageContent.match('RM0 ');

    if (
      (tags.length > 0 || selectedGroup.length > 0) &&
      messageContent !== testMessageContent[0]
    ) {
      this.setState({ sendNowLoading: true });
      const groupArray = [];
      const data = {
        phone: tags,
        message: messageContent,
      };

      if (selectedGroup.length > 0) {
        selectedGroup.map((group) => groupArray.push(group.value));
      }

      data.group = groupArray;

      postRequest('/message/send', data, this.props.auth.token, (res) => {
        if (res.success) {
          Swal.fire('Success!', 'Message was sent.', 'success');
          this.resetStates();

          setTimeout(() => {
            this.props.getUserCredits(this.props.auth.token);
          }, 2000);
        } else {
          Swal.fire(
            'Failed!',
            `Message was not sent. <br /> ${res.message}`,
            'error'
          );
          this.setState({ sendNowLoading: false });
        }
      });
    } else {
      if (tags.length <= 0 && selectedGroup.length <= 0) {
        this.checkVal('tagsSelectedGroup', tags);
        this.checkVal('tagsSelectedGroup', selectedGroup);
      }

      this.checkVal('messageContent', messageContent);
    }
  }

  handleSendLater() {
    const {
      tags,
      selectedGroup,
      messageContent,
      scheduleName,
      preferedDateTime,
    } = this.state;
    if (scheduleName.trim() !== '' && preferedDateTime !== null) {
      const groupArray = [];
      const data = {
        phone: tags,
        message: messageContent,
        name: scheduleName,
        runAt: moment(preferedDateTime).toISOString(),
      };

      if (selectedGroup.length > 0) {
        selectedGroup.map((group) => groupArray.push(group.value));
      }

      data.group = groupArray;

      postRequest('/message/send/later', data, this.props.auth.token, (res) => {
        if (res.success) {
          Swal.fire(
            'Success!',
            `Message is scheduled to be sent on ${moment(
              preferedDateTime
            ).format('DD MMMM YYYY, h:mm:ss a')}.`,
            'success'
          );
          this.resetStates();
          this.getSchedulesData(true);
        } else {
          Swal.fire('Failed!', `Schedule time or date is invalid`, 'error');
          this.setState({ sendNowLoading: false });
        }
      });
    } else {
      this.checkVal('scheduleName', scheduleName);
      this.checkVal('preferedDateTime', preferedDateTime);
    }
  }

  handleShortenURL() {
    this.setState({ shortenURLLoading: true });
    const data = {
      message: this.state.messageContent,
    };

    postRequest('/message/shorten-url', data, this.props.auth.token, (res) => {
      if (res.success) {
        this.setState({
          messageContent: res.data,
          shortenURLLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ shortenURLLoading: false });
      }
    });
  }

  handleUpdateScheduleStatus(id, status, runAt, e) {
    if (status !== 'done' && status !== 'on progress') {
      if (e) {
        // Check if e is there to avoid UI crashing
        if (moment(runAt).format() < moment().format()) {
          // Check if schedule date and time has passed current time or not
          if (!e.target.className.includes('done')) {
            // If already passed but no .done class included yet, add it and rerender to update frontend
            cogoToast.error('Scheduled running date and time has passed', {
              position: 'top-right',
            });
            this.getSchedulesData();
            return;
          } else {
            // If already passed and already have .done class included, just return and don't rerender frontend
            return;
          }
        }
      }

      const data = {
        scheduleId: id,
        status: status === 'waiting' ? 'disable' : 'enable',
      };

      putRequest(
        '/message/schedule/status',
        data,
        this.props.auth.token,
        (res) => {
          if (res.success) {
            this.getSchedulesData();
          } else {
            // console.log(res);
            cogoToast.error(res.message, { position: 'top-right' });
          }
        }
      );
    }
  }

  handleUserInput(e) {
    const { id, value } = e.target;

    if (id === 'messageContent') {
      if (GsmCharsetUtils.getCharCount(value).msgCount < 4) {
        if (value.includes('RM0 ')) {
          this.setState({ [id]: value }, () => {
            if (this.state.messageContentError) {
              this.setState({
                messageContentError: false,
              });
            }
          });
        } else {
          this.setState({ [id]: 'RM0 ' }, () => {
            if (this.state.messageContentError) {
              this.setState({
                messageContentError: false,
              });
            }
          });
        }
      } else {
        cogoToast.error('Maximum message character is 459', {
          position: 'top-right',
        });
      }
    } else {
      this.setState({ [id]: value }, () => {
        if (id === 'scheduleName') {
          if (this.state.scheduleNameError) {
            this.setState({
              scheduleNameError: false,
            });
          }
        }
      });
    }
  }

  resetStates() {
    this.setState({
      tag: '',
      tags: [],
      selectedGroup: [],
      messageContent: 'RM0 ',
      scheduleName: '',
      preferedDateTime: null,
      dateValue: '',
      showSendLaterModal: false,
      scheduleListLoading: false,
      sendNowLoading: false,
      sendLaterLoading: false,
      shortenURLLoading: false,

      /* Error Handling */
      tagsError: false,
      selectedGroupError: false,
      messageContentError: false,
      scheduleNameError: false,
      preferedDateTimeError: false,
    });
  }

  handleChangeTagInput(tag) {
    const isValid = phoneRegex.test(tag);

    if (isValid || tag === '') {
      this.setState(
        {
          tag,
        },
        () => {
          if (this.state.tagsSelectedGroupError) {
            this.setState({
              tagsSelectedGroupError: false,
            });
          }
        }
      );
    }
  }

  handleChangeTags(tags) {
    if (tags.length > 0) {
      if (isValidPhoneNumber(tags[tags.length - 1], 'MY')) {
        this.setState({
          tag: '',
          tags: tags,
        });
      } else {
        cogoToast.error('Invalid Phone Number', { position: 'top-right' });
      }
    } else {
      this.setState({
        tag: '',
        tags: tags,
      });
    }
  }

  handleSelectGroup(e) {
    this.setState(
      {
        selectedGroup: e,
      },
      () => {
        if (this.state.tagsSelectedGroupError) {
          this.setState({
            tagsSelectedGroupError: false,
          });
        }
      }
    );
  }

  handleShowSendLaterModal() {
    const { tags, messageContent, selectedGroup } = this.state;
    const testMessageContent = messageContent.match('RM0 ');

    if (!this.state.showSendLaterModal) {
      if (
        (tags.length > 0 || selectedGroup.length > 0) &&
        messageContent !== testMessageContent[0]
      ) {
        this.setState({
          showSendLaterModal: !this.state.showSendLaterModal,
        });
      } else {
        if (tags.length <= 0 && selectedGroup.length <= 0) {
          this.checkVal('tagsSelectedGroup', tags);
          this.checkVal('tagsSelectedGroup', selectedGroup);
        }

        this.checkVal('messageContent', messageContent);
      }
    } else {
      this.setState({
        showSendLaterModal: !this.state.showSendLaterModal,
      });
    }
  }

  handleDatePicker(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm:ss A')
      );

      this.setState({
        dateValue: dateValue,
        preferedDateTime: p.startDate,
        preferedDateTimeError: false,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        dateValue: dateValue,
        preferedDateTime: null,
        preferedDateTimeError: false,
      });
    }
  }

  handlePageChange(pageNo) {
    this.setState(
      {
        currentPage: pageNo,
      },
      () => {
        this.getSchedulesData();
      }
    );
  }

  render() {
    const smsCountObj = GsmCharsetUtils.getCharCount(this.state.messageContent);
    const {
      handleChangeTagInput,
      handleChangeTags,
      handleShowSendLaterModal,
      handleUserInput,
      handleSendLater,
      handleDatePicker,
    } = this;

    return (
      <div className="SendMessage">
        <div className="row">
          <div className="col-12">
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-envelope',
                  title: 'Send Message',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="dateRange1">
                      <Form.Label>Message Parameter</Form.Label>
                      <AddPhoneNumber
                        data={this.state}
                        handlers={{ handleChangeTagInput, handleChangeTags }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="selectGroup">
                      <Select
                        isMulti
                        name="selectGroup"
                        placeholder="Select Group"
                        options={this.state.groupListFilter}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={this.state.selectedGroup}
                        styles={{
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#f58025',
                            color: '#fff',
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: '#fff',
                          }),
                          control: (base) => ({
                            ...base,
                            border: this.state.tagsSelectedGroupError
                              ? '1px solid #dc3545'
                              : '1px solid #ced4da',
                          }),
                        }}
                        onChange={(e) => this.handleSelectGroup(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="messageContent">
                      <Form.Control
                        as="textarea"
                        rows="7"
                        className={
                          this.state.messageContentError ? 'is-invalid' : ''
                        }
                        placeholder="Enter Message Content"
                        value={this.state.messageContent}
                        onChange={(e) => handleUserInput(e)}
                      />
                      {this.state.tagsSelectedGroupError && (
                        <Form.Text>
                          Input a destination number or select a group
                        </Form.Text>
                      )}
                      {this.state.messageContentError && (
                        <Form.Text>Message content is required</Form.Text>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-3">
                    <button
                      className="btn shorten-url"
                      onClick={() => this.handleShortenURL()}
                    >
                      {this.state.shortenURLLoading ? (
                        <div className="loading-white-bg">
                          <Loading />
                        </div>
                      ) : (
                        <HOC>
                          <i className="bx bx-cut" />
                          <span>Shorten URL</span>
                        </HOC>
                      )}
                    </button>
                  </div>
                  <div className="col-6 col-md-3 text-right">
                    <span>
                      {`CHARS ${smsCountObj.charCount} / ${
                        smsCountObj.msgCount * smsCountObj.charsPerSegment
                      } | ${smsCountObj.msgCount} SMS`}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 my-3">
                    <label className="mcmc-regulation">
                      DUE TO MCMC REGULATION IN MALAYSIA, ALL SENT MESSAGES WILL
                      BE APPENDED BY "RM0 " PREFIX
                    </label>
                  </div>
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
                    <button
                      type="submit"
                      className="btn main-btn icon-btn"
                      onClick={() => this.handleSendNow()}
                      disabled={this.state.sendNowLoading}
                    >
                      {this.state.sendNowLoading ? (
                        <Loading />
                      ) : (
                        <HOC>
                          <i className="bx bx-mail-send" />
                          <span>Send Now</span>
                        </HOC>
                      )}
                    </button>
                    <button
                      type="submit"
                      className="btn main-btn icon-btn ml-2"
                      onClick={() => handleShowSendLaterModal()}
                      disabled={this.state.sendLaterLoading}
                    >
                      {this.state.sendLaterLoading ? (
                        <Loading />
                      ) : (
                        <HOC>
                          <i className="bx bx-timer" />
                          <span>Send Later</span>
                        </HOC>
                      )}
                    </button>
                  </div>
                </div>
                <SendMessageTable
                  data={this.state}
                  handleUpdateScheduleStatus={this.handleUpdateScheduleStatus}
                  handlePageChange={this.handlePageChange}
                />
              </Card.Body>
            </WrapperCard>
          </div>
        </div>
        <SendLaterModal
          data={this.state}
          handlers={{
            handleShowSendLaterModal,
            handleUserInput,
            handleSendLater,
            handleDatePicker,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getUserCredits: (token) => dispatch(getUserCredits(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendMessage);
