import React from 'react';
import Select from 'react-select';
import { Card, Form } from 'react-bootstrap';
import HOC from '../shared/HOC';
import Loading from '../shared/Loading/Loading';
import WrapperCard from '../shared/Cards/WrapperCard';

const EditAccount = (props) => (
  <WrapperCard
    header={{
      exist: true,
      data: { iconName: 'bx bx-user', title: 'My Account' },
    }}
  >
    <Card.Body>
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <strong>Company Info</strong>
          </span>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              placeholder={props.data.companyName}
              type="text"
              disabled={true}
            />
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="companyCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              placeholder={props.data.companyCountry}
              type="text"
              disabled={true}
            />
          </Form.Group>
        </div>
        <div className="col-12">
          <Form.Group controlId="companyFullAddress">
            <Form.Label>Full Address</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Enter Full Address"
              value={props.data.companyFullAddress}
              onChange={(e) => props.handlers.handleUserInput(e)}
            />
          </Form.Group>
        </div>
      </div>
    </Card.Body>
    <Card.Body className="py-0">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <strong>User Info</strong>
          </span>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="userName">
            <Form.Label>User's Name</Form.Label>
            <Form.Control
              placeholder="Name"
              className={props.data.userNameError ? 'is-invalid' : ''}
              type="text"
              value={props.data.userName}
              onChange={(e) => props.handlers.handleUserInput(e)}
            />
            {props.data.userNameError !== '' && (
              <Form.Text>{props.data.userNameError}</Form.Text>
            )}
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              placeholder="Phone Number"
              type="number"
              value={props.data.phoneNumber}
              onChange={(e) => props.handlers.handleUserInput(e)}
            />
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="skypeID">
            <Form.Label>Skype ID</Form.Label>
            <Form.Control
              placeholder="Skype ID"
              type="text"
              value={props.data.skypeID}
              onChange={(e) => props.handlers.handleUserInput(e)}
            />
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="position">
            <Form.Label>Position</Form.Label>
            <Form.Control
              placeholder="Position"
              type="text"
              value={props.data.position}
              onChange={(e) => props.handlers.handleUserInput(e)}
            />
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="utc">
            <Form.Label>Select UTC</Form.Label>
            <Select
              name="utc"
              placeholder="Select UTC"
              options={props.data.utcList}
              defaultValue={props.data.defaultUTC}
              className="basic-single"
              classNamePrefix="select"
              menuPlacement="top"
              styles={{
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#f58025',
                  color: '#fff',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: '#fff',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: '3',
                }),
              }}
              onChange={(e) => props.handlers.handleSelectUTC(e)}
            />
          </Form.Group>
        </div>
      </div>
    </Card.Body>
    <Card.Body>
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <strong>Encoding Autodetect</strong>
          </span>
        </div>
        <div className="col-12">
          <Form.Check
            type="switch"
            id="encodingAutodetect"
            label="Use Encoding Autodetect"
            checked={props.data.encodingAutodetect}
            onChange={(e) => props.handlers.handleUserInput(e, 'check')}
          />
        </div>
        <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
          <button
            type="submit"
            className="btn main-btn icon-btn"
            onClick={() => props.handlers.handleUpdateMyAccount()}
            disabled={props.isLoading}
          >
            {!props.isLoading ? (
              <HOC>
                <i className="bx bx-save" />
                <span>Save Settings</span>
              </HOC>
            ) : (
              <Loading />
            )}
          </button>
        </div>
      </div>
    </Card.Body>
  </WrapperCard>
);

export default EditAccount;
