import React from 'react';
import Form from 'react-bootstrap/Form';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const ForgotPasswordModal = (props) => (
  <WrapperModal
    show={props.showForgotPassword}
    onClose={props.handlers.handleCloseModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Recover your password',
            handleCloseModal: props.handlers.handleCloseModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Reset Password',
            handleCloseModal: props.handlers.handleCloseModal,
            handleActionButton: props.handlers.handleForgotPassword,
            isLoading: props.data.isLoading,
          }}
        />
      ),
    }}
    centered={true}
    styles="ForgotPassword"
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleForgotPassword}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="forgotPasswordEmail">
                <Form.Label>Email your email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="user@email.com"
                  className={
                    props.data.forgotPasswordEmailError ? 'is-invalid' : ''
                  }
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.forgotPasswordEmailError && (
                  <Form.Text>Email is required</Form.Text>
                )}
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default ForgotPasswordModal;
